module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3_mzgkc2m2qi6xiow42ibrz5hjpm/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"McGowin Tax","short_name":"McGowin Tax","start_url":"/","display":"minimal-ui","icon":"src/images/favicon.png","background_color":"#FAFEFF","theme_color":"#007FE0","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f178b328c44f3b760dc9d271282d66ab"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@8.57.1__react-do_iaoufcwkqc55ex7xeyo3ls7vzi/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://mcgowintax.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@8.57.1__react-dom@1_47eovmsfzw7un27bjvlk2eb5m4/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4691D2KD9W"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3.1_react@18.3.1__react@18.3.1__jvoaqimqmygsa5j6643tomfjbu/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
